.goal {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    max-width: 100%;

    @media screen and (min-width: 500px) {
        flex-direction: row;
    }

    & > div {
        display: inline-block;
    }

    .left-container {
        display: flex;

        @media screen and (min-width: 500px) {
            width: 75%;
        }

        .icon-container {
            display: inline-block;
            width: 50px;
            display: flex;
            align-content: center;

            & > * {
                margin-right: 5px;
            }
        }

        .icon-container--compact {
            width: auto;
        }

        .goal-label {
            display: inline;
            padding-right: 10px;
        }

        .goal-label--compact {
            font-size: small;
        }
    }
    .area-tag {
        display: flex;
        justify-content: flex-start;

        @media screen and (min-width: 500px) {
            width: 25%;
            justify-content: flex-end;
        }

        .tag {
            max-width: fit-content;
            display: flex;
            justify-content: flex-start;
            
            p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis
            }
        }
    }
}

.note {
    background-color: lightyellow;
    padding: 5px;
    margin-block: 10px;
    font-size: smaller;
}

.tag:hover {
    background-color: lightgray;
}

.time-section {
    .header {
        display: flex;
        align-items: center;

        .time-section-title {
            font-size: larger;
            font-weight: 550;
        }
        
        .button {
            display: inline-block;
            width: 30px;
            height: 30px;
            display: flex;
        }
    }
}

.starred {
    color: rgb(255, 215, 0);

    &:hover {
        color: rgb(255, 175, 0);
    }
}

.unstarred {
    color: hsl(0, 0%, 90%);

    &:hover {
        color:hsl(0, 0%, 75%);
    }
}